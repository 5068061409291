import * as React from 'react'
import * as css from './SearchResults.module.css'
import { SearchContext } from './SearchContext'
import { go } from 'fuzzysort'
import Result from './Result'

const SearchResults = ({ q }) => {
  const { allPages } = React.useContext(SearchContext)

  const results = go(q, allPages, {
    key: 'title',
    allowTypo: false,
    threshold: -10000,
  })?.map(({ obj }) => obj)

  return (
    <section className="container padding">
      <ul className={css.list}>
        {results?.map((result, key) => (
          <li className={css.result} key={key}>
            <Result {...result} q={q} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default SearchResults
