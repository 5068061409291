import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { SearchProvider } from 'components/search/SearchContext'
import SearchResults from 'components/search/SearchResults'

const meta = {
  title: 'Search',
}

const SearchPg = ({ location }) => {
  const [$q, set$q] = React.useState(null)

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('q')) {
      set$q(params.get('q'))
    }
  }, [set$q, location.search])

  return (
    <DefaultLayout {...meta}>
      <SearchProvider>
        <section className="container padding">
          <h1 className="h1 cps-blue">Search results for "{$q}"</h1>
        </section>

        <SearchResults q={$q} />
      </SearchProvider>
    </DefaultLayout>
  )
}

export default SearchPg
