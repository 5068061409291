import * as React from 'react'
import highlight from 'utils/highlight'
import newtab from 'utils/newtab'
import { Link } from 'gatsby'

const Result = ({ title, url, seo, type, q }) => {
  let $url = url || (
    type === 'Blog' ? `/news/${ seo.slug.current }`
    : type === 'Underwriting' ? `/services-tools/underwriting/${ seo.slug.current }`
    : !!seo ? `/${ seo.slug.current }`
    : null
  )

  if (!$url) return null

  const isExternal = $url?.startsWith('http')

  return (
    <article>
      <p className="text-dark uppercase lg">
        <small>{type || 'Page'}</small>
      </p>

      <h3 dangerouslySetInnerHTML={{ __html: highlight(title, q, 'b') }} />

      {isExternal
        ? <a className="link" href={$url} {...newtab}>View</a>
        : <Link className="link" to={$url}>View</Link>
      }
    </article>
  )
}

export default Result
